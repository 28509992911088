<template>
    <div class="venue">
        <div class="venue-tabs">
            <div class="venue-li-tab" v-for="(item,index) in tabsParam" @click="toggleTabs(index)"
                :class="{active:index == nowIndex}" :key="index">
                <p>{{item.name}}</p>
                <span>({{item.count}})</span>
            </div>
        </div>
        <div class="divTab" v-show="nowIndex === index">
            <div class="venue-list" v-for="(item,index) in venueInfoList" :key="index" @click="goDetail(index)">
                <img :src="item.thumbnail" alt="">
                <div class="venue-list-center">
                    <div>
                        <h2>{{item.name}}</h2>
                        <span class="grand-hall" v-if="item.type == 0">#小馆#</span>
                        <span class="grand-hall" v-if="item.type == 1">#大馆#</span>
                        <span class="grand-hall" v-if="item.type == 2">#体育场#</span>
                        <span class="grand-hall" v-if="item.type == 3">#篮球馆#</span>
                    </div>
                    <p>{{item.address}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { listVenueArea } from "../../api/home"
import { getVenueInfoByAreaId } from "../../api/venue"
export default {
    data(){
        return{
            tabsParam: [],
            nowIndex: 0,
            index:0,
            id:2,
            venueInfoList:[],
            detailId:""
        }
    },
    created(){
        
    },
    mounted(){
        // this.nowIndex = this.$route.query.index
        // this.index = this.$route.query.index

        this.listVenueArea()
        // console.log(this.$route.query.index)


        // this.id = this.$route.query.id

        // if(this.$route.query.id){
        //     this.id = this.$route.query.id
        // }else{
        //     this.id =  JSON.parse(localStorage.getItem('listId')).id
        // }

        this.id = this.$route.query.id
        this.nowIndex = this.$route.query.index
        this.index = this.$route.query.index

        // if(this.$route.query.index){
        //     this.nowIndex = this.$route.query.index
        //     this.index = this.$route.query.index
        // }else{
        //     this.nowIndex =  JSON.parse(localStorage.getItem('listId')).index
        //     this.index =  JSON.parse(localStorage.getItem('listId')).index
        //     console.log(this.nowIndex)
        //     console.log(this.index)
        // }

        // localStorage.removeItem('listId')
        this.getVenueInfoByAreaId()
    },
    methods:{
        toggleTabs(index) {
            this.id = this.tabsParam[index].id
            // console.log(this.id)
            this.nowIndex = index;
            this.index = index
            this.getVenueInfoByAreaId()
        },

        goDetail(index){
            this.detailId = this.venueInfoList[index].id
            // console.log(this.id)
            this.$router.push({
                name: "detail", 
                params: {
                    id:this.detailId,
                }
            });
            localStorage.setItem('listId', JSON.stringify({id:this.id,index:this.index}));
        },

        // 标题
        listVenueArea(){
            listVenueArea({
                type:0
            }).then((res) => {
                if(res.data.code == 20000){
                    this.tabsParam = res.data.data.venueAreaList
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },

        //列表
        getVenueInfoByAreaId(){
            console.log('接口',this.id)
            getVenueInfoByAreaId({
                id:this.id
            }).then((res) => {
                if(res.data.code == 20000){
                    this.venueInfoList = res.data.data.venueInfoList
                    this.$forceUpdate()   
                }else if(res.data.code == 20005){
                    this.$message.error(res.data.message);
                    this.$router.push("/login")
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.venue{
    width:100%;
    background-color:#F8F8F8;
    .venue-tabs{
        width:100%;
        height:60px;
        background-color:#FFFFFF;
        display:flex;
        align-items: flex-end;
        padding:0 40px;
        .venue-li-tab{
            padding:15px 12px;
            color:#282828;
            font-size:16px;
            display:flex;
            align-items:center;
            justify-content: center;
            margin-right:10px;
            cursor: pointer;
        }
        .active{
            background-color:#F8F8F8;
            border-radius: 4px 4px 0px 0px;
            color:#FA6400;
        }
    }
    .divTab{
        width:100%;
        padding: 20px 30px 48px 45px;
        display:flex;
        align-items:center;
        flex-wrap: wrap;
        .venue-list{
            width:350px;
            margin:0 15px 15px 0;
            cursor: pointer;
            img{
                width:100%;
                height:225px;
                border-radius: 6px 6px 0px 0px;
            }
            .venue-list-center{
                width:100%;
                height:68px;
                background-color:#FFFFFF;
                border-radius: 0px 0px 6px 6px;
                margin-top:-3px;
                display:flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 15px;
                div{
                    display:flex;
                    align-items:center;
                    justify-content: space-between;
                    h2{
                        color:#282828;
                        font-size:16px;
                        font-weight:600;
                    }
                }
                p{
                    color:#BBBBBB;
                    font-size:12px;
                    padding-top:8px;
                }
            }
        }
    }
}
</style>